.box {
  width: 100%;
  padding-top: 1%;
  background-color: #eee;
  display: flex;
  justify-content: center;
}
.table {
  width: 98%;
}

.headers {
  width: 100%;
  display: grid;
  margin-bottom: 1%;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  font-size: 1.1em;
  text-align: center;
  font-weight: 600;
  color: white;
  background-color: #11101d;
  padding: 1%;
  border-radius: 5px;
}
.headers div{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.trow {
  width: 100%;
  padding: 1%;
  margin-bottom: 0.5%;
  display: grid;
  background-color: white;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  border-radius: 5px;
}
.trow:hover {
  background-color: #11101d;
  color: white;
  transition: 0.3s;
  cursor: pointer;
}
.td {
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.down-arrow {
  filter: invert(53%) sepia(1%) saturate(931%) hue-rotate(190deg)
    brightness(100%) contrast(100%);
}
.up-arrow {
  filter: invert(53%) sepia(1%) saturate(931%) hue-rotate(190deg)
    brightness(100%) contrast(100%);
}
.drop-down {
  margin-right: 1%;
  padding: 5px;
  border: 2px solid white;
  background-color: #11101d;
  color: white;
  border-radius: 5px;
}
.pagination-container {
  margin: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goto-btn {
  border: none;
  background-color: white;
  color: #11101d;
  border-radius: 5px;
  margin: 5px;
  padding: 12px 15px 8px 15px;
}
.inv-goto-btn{
  cursor: not-allowed;
  color: #d3d3d3;
  background-color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 12px 15px 8px 15px;
}
.goto-btn:hover{
  transition: 0.3s;
  background-color: #11101d;
  color: white;
}
.page-btn{
  font-weight: 500;
  border: none;
  color: #11101d;
  border-radius: 5px;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 10px 15px;
}
.inv-page-btn{
  font-weight: 500;
  border: none;
  cursor: not-allowed;
  border-radius: 5px;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 10px 15px;
}
.page-btn:hover{
  transition: 0.3s;
  background-color: #11101d;
  color: white;
}


.page-count{
  font-size: 0.9em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0 10px;
  padding: 10px 15px;
}

.expired-data{
  padding: 10px 0;
  margin-top: 10px;
  font-size: 20px;
  background-color: white;
  border-radius: 5px;
}