/* .view-customer-box {
  display: flex;
  padding: 2%;
}
.view-option {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 0%;
  width: 100%;
}
.card-container{
  width: 20%;
}
.view-option-header {
  background-color: #212529;
  color: white;
  border-radius: 0% !important;
}
.view-option-header span {
  text-transform: uppercase;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.option-item {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.1em;
}
.option-item:hover {
  background-color: #2c3034;
  color: white;
}

.selected {
  background-color: #2c3034;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.1em;
}
.dropdown {
  margin: 0;
  text-align: left;
  padding: 0.5rem 1rem;
  border: none;
  width: 100%;
  border-radius: 0%;
  background-color: transparent;
  color: #2c3034;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.1em;
}
.dropdown-selected{
  background-color: #2c3034;
  text-align: left;
  padding: 0.5rem 1rem;
  border: none;
  width: 100%;
  border-radius: 0%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.1em;
}
.dropdown:hover{
  background-color: #2c3034;
  color: white;
} */

.customer-detail {
  margin-top: 1%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.customer-detail-with-contact-person {
  margin-top: 1%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.customer-box1 {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 0 2% 2% 2%; */
  box-shadow: 1px 1px 5px grey;
  position: relative;
}

.customer-box1 h2 {
  font-size: 1.3em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.customer-box1 h6,
h5 {
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: grey;
}
.avatar-img {
  width: 100px;
  border-radius: 50%;
  height: 100px;
  margin: 0;

}
.contact-person-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.drop-down {
  color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgb(222, 222, 222);
  margin-right: 10px;
}

.drop-down button {
  background-color: #11101d;
  color: white;
}
.drop-down button:hover {
  background-color: #11101d;
  color: white;
}
.drop-down a {
  text-transform: capitalize;
}

.drop-down span {
  font-size: 1.1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.add-contact-btn {
  background-color: #11101d;
  color: white;
  border: none;
  border: none;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgb(197, 197, 197);
}

.customer-box2 {
  background-color: white;
  box-shadow: 1px 1px 5px grey;
  border-radius: 5px;
  margin: 1% 1% 1% 0;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.sm-bx {
  display: flex;
  width: 95%;
  box-shadow: 1px 1px 5px rgb(222, 222, 222);
  background-color: whitesmoke;
  padding: 2%;
  border-radius: 5px;
  margin-bottom: 10px;
}
.sm-bx div img {
  width: 30px;
}
.sm-bx div:nth-of-type(1) {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.sm-bx div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
}
.sm-bx span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1em;
  font-weight: 500;
}
.sm-bx span:nth-of-type(1) {
  white-space: nowrap;
}
.sm-bx span:nth-of-type(2) {
  color: grey;
  font-weight: 400;
  font-size: 1em;
  text-transform: capitalize;
}

.customer-box3 {
  background-color: white;
  box-shadow: 1px 1px 5px grey;
  border-radius: 5px;
  margin: 1% 1% 1% 0;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.customer-box4 {
  background-color: white;
  margin: 1% 1% 1% 0;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2%;
  box-shadow: 1px 1px 5px grey;
  position: relative;
}
.customer-box4 h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.customer-box4 h6,
h5,
h4 {
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: grey;
}
.contact-img {
  width: 40%;
  margin-bottom: 5%;
}

.customer-box5 {
  background-color: white;
  box-shadow: 1px 1px 5px grey;
  border-radius: 5px;
  margin: 1% 1% 1% 0;
  padding: 2%;
}

.customer-edit-btn {
  background-color: #11101d;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.customer-edit-btn img {
  width: 25px;
  margin-right: 5px;
}
.customer-edit-btn span:nth-of-type(2) {
  font-size: 1.1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.customer-box5 + div {
  display: flex;
  justify-content: end;
}
.customer-edit-btn:hover {
  background-color: #222039;
}
.select-service {
  display: flex;
  justify-content: space-between;
  margin: 4% 0 2% 0;
}
.not-selected {
  color: rgb(66, 66, 66);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  padding: 5px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  margin: 1%;
}
.selected {
  box-shadow: 1px 1px 5px white;
  transition: 0.3s;
  color: #11101d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  border-radius: 5px;
  background-color: #dadada;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1%;
}
.check-icon {
  width: 28px;
}
.delete-btn {
  color: #11101d;
  background-color: whitesmoke;
  border: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  padding: 3px 5px 2px 5px;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.delete-btn:hover {
  color: rgb(114, 29, 29);
  background-color: rgb(245, 245, 245);
  box-shadow: 1px 1px 5px rgb(199, 199, 199);
  transition: 0.3s;
  transform: scale(1.2);
}
.delete-btn ion-icon {
  margin: 0 !important;
}
