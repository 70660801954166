.search-Bar{
    display: flex;
    justify-content: flex-end;
}
.search-Bar input {
font-size: 1em;
padding: 4px 5px;
border-radius: 5px; 
border: none;
outline: none;
margin-right: 10px;
}
