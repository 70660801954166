.container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 1%;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;
  padding-bottom:1% ;
  border-bottom: 2px solid #eee;
}
.heading h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}
.heading span {
  display: flex;
}
.heading span select {
  border: 2px solid #11101d;
  background-color: #11101d;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
 
}
option span{
  padding: 10px;
}

.table {
  width: 100%;

}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  font-size: 1.1em;
  text-align: center;
  color: grey;
  padding: 0 1%;
  border-radius: 5px;
  margin-bottom: 1%;
}
.header th {
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
.body-row {
  width: 100%;
  padding: 1% 0;
  margin-bottom: 0.5%;
  display: grid;
  background-color: #eee;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  border-radius: 5px;
  padding-right: 2%;
}
.body-row:hover {
  background-color: #11101d;
  color: white;
  box-shadow: 1px 1px 10px #11101d;
  transition: 0.3s;
  cursor: pointer;
}
.td {
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.down-arrow {
  filter: invert(53%) sepia(1%) saturate(931%) hue-rotate(190deg)
    brightness(100%) contrast(100%);
  height: 15px;
}
.up-arrow {
  filter: invert(53%) sepia(1%) saturate(931%) hue-rotate(190deg)
    brightness(100%) contrast(100%);
  height: 15px;
}
.transparent {
  visibility: hidden;
  height: 15px;
}

.pagination-container {
  margin: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goto-btn {
  color: #77767f;
  background-color: white;
  border: none;
  cursor: pointer;
  margin: 0 2%;
  padding-top: 3px;
}
.inv-goto-btn {
  cursor: not-allowed;
  color: #d3d3d3;
  background-color: white;
  border: none;
  margin: 0 2%;
  padding-top: 3px;
}
.goto-btn:hover {
  transition: 0.3s;
  color: #11101d;
}
.page-btn {
  font-weight: 500;
  border: none;
  color: #77767f;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 10px 15px;
  cursor: pointer;
}
.inv-page-btn {
  font-weight: 500;
  border: none;
  cursor: not-allowed;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 10px 15px;
}
.page-btn:hover {
  transition: 0.3s;
  color: #11101d;
}
.page-count {
  font-size: 0.9em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0 10px;
  padding: 10px 15px;
}
.expired-data{
  padding: 10px 0;
  margin-top: 10px;
  font-size: 20px;
}