.inp-box {
  padding: 0 0%;
  width: 45%;
}
.inp {
  width: 95%;
  height: 40px;
  padding: 5px;
  font-size: 15px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
}
.label {
  text-transform: capitalize;
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}
.down-btn {
  position: absolute;
  width: 2%;
  height: 40px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.option-container {
  padding: 5px 0 5px 0;
  width: 39.5%;
 max-height: 33vh;
  position: absolute;
  background-color: white;
  z-index: 100;
  border: 1.5px solid rgb(208, 208, 208);
  border-radius: 5px;
  overflow-y: scroll;
}
.option {
  padding: 5px 10px;
  text-transform: capitalize;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.option:hover {
  background-color: rgba(70, 206, 255, 0.18);
}
