.container {
  width: 100%;
  padding-top: 1%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table {
  width: 98%;
}

.header {
  width: 100%;
  display: grid;
  margin-bottom: 1%;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  font-size: 1.1em;
  text-align: center;
  color: white;
  background-color: #11101d;
  padding: 1%;
  border-radius: 5px;
}
.header th {
  font-family: "Segoe UI";
  font-weight: 600;
  width: auto;
}
.body-row {
  width: 100%;
  padding: 1%;
  margin-bottom: 0.5%;
  display: grid;
  background-color: white;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  border-radius: 5px;
}
.body-row:hover {
  background-color: #11101d;
  color: white;
  transition: 0.3s;
  cursor: pointer;
}
.td {
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  font-family: "Segoe UI";
}

.down-arrow {
  filter: invert(53%) sepia(1%) saturate(931%) hue-rotate(190deg)
    brightness(100%) contrast(100%);
}
.up-arrow {
  filter: invert(53%) sepia(1%) saturate(931%) hue-rotate(190deg)
    brightness(100%) contrast(100%);
}
.drop-down {
  margin-right: 1%;
  padding: 5px;
  border: 2px solid white;
  background-color: #11101d;
  color: white;
  border-radius: 5px;
}
.label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 5px 0 0;
}
.pagination-container {
  margin: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goto-btn {
  border: none;
  background-color: white;
  color: #11101d;
  border-radius: 5px;
  margin: 5px;
  padding: 12px 15px 8px 15px;
}
.inv-goto-btn {
  cursor: not-allowed;
  color: #d3d3d3;
  background-color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 12px 15px 8px 15px;  
}
.goto-btn:hover {
  transition: 0.3s;
  background-color: #11101d;
  color: white;
}
.page-btn {
  font-weight: 500;
  border: none;
  color: #11101d;
  border-radius: 5px;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 10px 15px;
  
}
.inv-page-btn {
  font-weight: 500;
  border: none;
  cursor: not-allowed;
  border-radius: 5px;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 10px 15px;

}
.page-btn:hover {
  transition: 0.3s;
  background-color: #11101d;
  color: white;
}
.page-count {
  font-size: 1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0 10px;
  padding: 10px 15px;
}
.expired-data .td {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  padding: 10px 0;
  background-color: white;
  border-radius: 5px;
}
