.inp-box{
  width: 100%;
  display: flex;
  justify-content: center;
}
.table{
  width: 98%;
  background-color: #eee;
  margin: 1% 0 1% 1%;
  text-align: center;
  transition: 0.5s;
  border-collapse: separate;
  border-spacing: 0 0.4em;
}


.thead {
  background-color: #11101d;
  color: white;
  height: 7vh;
  border: 2px solid #11101d;
}

.thead th {
  border-right: 2px solid #11101d;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.1em;
}

.thead th:nth-of-type(1) {
  width: 5vw;
}
.thead th:last-of-type {
  width: 10vw;

}

.tbody tr {
  background-color: white;
  height: 6vh;
}

.tbody td {
  font-size: 1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}

.no-data{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 500;
  background-color: white;
  height: 5vh;
}