
.select {
  border: 1px solid #ced4da;
  color: black;
  border-radius: 5px;
  width: 14vw;
  margin-left: 2%;
  height: 6vh;
  padding: 10px;
  font-size: 15px;
}
.select-box{
  display: flex;
  align-items: center;
}
.container{
  width: 99%;
  display: flex;
  justify-content: space-between;
}

.inp-box {
  padding: 0 0%;
  width: 45%;
}
.label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}

.drop-down {
  width: 100%;
  height: 6vh;
  padding: 5px;
  font-size: 15px;
  border:1px solid #ced4da;
  border-radius: 4px;
} 