.app{
    background-color: #eee;
    height: 100vh;
}
::-webkit-scrollbar {
    width: 10px;
   
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #e2e2e2;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    height: 10%;
    background:rgba(0, 0, 0, 0.231);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
