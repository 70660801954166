.dlt-btn{
    background-color: transparent;
    border:none ;
    font-size: 25px;
    margin-left: 10%;
    color: red;
  }
.confirmation{
  margin: 3% 0;
}
.cancel-btn{
  padding: 5px 15px;
  border-radius: 5px;
  border: 2px solid rgb(178, 15, 15);
  background-color:transparent ;
  color: rgb(178, 15, 15);
  margin-right: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 15px;
}
.cancel-btn:hover{
  background-color: rgb(178, 15, 15);
  color: white;
}
.confirm-btn{
  padding: 5px 15px;
  border-radius: 5px;
  border: 2px solid rgba(18, 124, 11, 0.774);
  background-color:transparent;
  color:rgba(18, 124, 11, 0.774) ;
  margin-right: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 15px;
}
.confirm-btn:hover{
  background-color: rgba(18, 124, 11, 0.774);
  color: white;
}

