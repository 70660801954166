.vendor-btn {
  font-size: 0.9em;
  padding: 4px 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  background-color: #11101d;
  color: white;
}

.select-box {
  width: 35vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  width: 10vw;
  text-align: end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 5px 0 0;
}
.label sup {
  margin-right: 10%;
}
