.project {
  width: 98%;
  margin-top: 2%;
  border-radius: 5px;
  padding: 0 1% 1% 1%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.project-detail-container {
  height: 89%;
}
.project-detail-box {
  width: 100%;
  height: 34%;
  margin-bottom: 1%;
}
.project-details {
  display: flex;
  justify-content: space-between;
  color: #11101d;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: white;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 1.1em;
  text-transform: capitalize;
  box-shadow: 1px 1px 5px grey;
}

.customer-name {
  background-color: #11101d;
  color: white;
  width: auto;
  display: inline-block;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 5px 8px;
  font-weight: 500;
  font-size: 1.1em;
  border-radius: 5px;
  text-transform: capitalize;
}
.project-details div span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  display: block;
}
.project-details div span:nth-of-type(1) {
  font-size: 1em;
  color: rgb(146, 146, 146);
}
.project-details div span:nth-of-type(2) {
  font-size: 0.9em;
}
.project-details div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
}
.project-description-container {
  width: 100%;
}
.project-description-container span {
  background-color: #11101d;
  color: white;
  width: auto;
  display: inline-block;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 5px 8px;
  font-weight: 500;
  font-size: 1.1em;
  border-radius: 5px;
}
.project-description {
  background-color: white;
  border: 1px solid #dddddd;
  width: 100%;
  max-height: 40vh;
  margin-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 30px;
  word-spacing: 3px;
  border-radius: 5px;
  padding: 10px;
  overflow-y: auto;
}

.project-btn-container {
  height: 10%;
  display: flex;
  justify-content: flex-end;
}
.edit-btn-container {
  margin-top: 20px;
}

.project-btn-container span {
  display: flex;
}
.project-edit-btn {
  background-color: transparent;
  color: white;
  padding: 5px 5px 5px 8px;
  margin: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
}

.project-edit-btn img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.delete-btn {
  background-color: transparent;
  color: rgb(210, 86, 86);
  padding: 0 10px;
  margin: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
}


.cancel-btn {
  color: #11101d;
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 7px 15px;
  margin-right: 10px;
  border: 1px solid #dddddd;
}
.submit-btn {
  color: white;
  background-color: #11101d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 7px 15px;
}
/* MODULE DESCRIPTION */

.module-name {
  background-color: #11101d;
  color: white;
  display: inline-block;
  box-shadow: 1px 1px 5px grey;
  margin-left: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 1.1em;
  border-radius: 5px;
  text-transform: capitalize;
}
.module-description-box {
  padding: 10px;
  line-height: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1em;
  border-radius: 5px;
}
.module-container {
  display: flex;
  justify-content: space-between;
  height: 40vh;
}
.module-container-edit {
  display: flex;
  justify-content: space-between;
  height: 50vh;
}
.module-details {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #11101d;
  background-color: white;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 1.1em;
  text-transform: capitalize;
}
.module-details div {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}
.module-details span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  color: grey;
}
.module-name span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.module-description {
  background-color: white;
  border: 1px solid #dddddd;
  line-height: 30px;
  word-spacing: 3px;
  width: 68%;
  margin-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 5px;
  padding: 10px;
  overflow-y: auto;
}

.module-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.edit-module-btn {
  background-color: transparent;
  color: white;
  padding: 5px 5px 5px 8px;
  margin: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
}

.edit-module-btn img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.delete-module-btn {
  background-color: transparent;
  color: rgb(210, 86, 86);
  padding: 0 10px;
  margin: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
}

.delete-module-btn ion-icon {
  font-size: 24px;
}

.inp {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}
.text-area {
  border: 1px solid #ced4da;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.module-inp {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
}
.edit-modulebtn-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
