.btn-group{
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    padding: 1%;
}
.btn-group button{
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px 20px;
}