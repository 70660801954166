.table{
  width: 98%;
  background-color: #eee;
  margin: 1% 0 1% 1%;
  text-align: center;
  transition: 0.5s;
  border-collapse: separate;
  border-spacing: 0 0.4em;
}


.thead {
  background-color: #11101d;
  color: white;
  height: 7vh;
  border: 2px solid #11101d;
}

.thead th {
  border-right: 2px solid #11101d;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.1em;
}

.tbody {
  background-color: #eee;
}
.trow {
  background-color: white;
  height: 6vh;

}

.tbody td {
  font-size: 1em;
  font-family: "Segoe UI";
  font-weight: 600;
}
.label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 5px 5px;
}

.select-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.select-box div:nth-of-type(1) {
  width: 50%;
  margin-left: 5%;
}
.select-box div:nth-of-type(2) {
  display: flex;
  justify-content: center;
  width: 50%;
}
.select {
  width: 73%;
  margin-right: 1%;
}
.inp-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-data{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  background-color: white;
  height: 5vh;
}
.effective-button{
  background-color: transparent;
  border: none;
  font-size:10px;
  margin-left: 10%;
  padding-top: 5px;
    color: rgb(11, 11, 78);;
}
.effective-button ion-icon{
  font-size: 24px;
}
