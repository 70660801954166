.select-box{
  width: 45%;
}
.inp-box {
  padding: 0 0%;
  width: 45%;
}

.select-option {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: rgb(32, 32, 32);
  padding: 10px;
  font-size: 15px;
}
.label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 5px 5px;
}
.text-box {
  width: 100%;
}
.text-area-label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 5px 5px;
}
.text-area {
  display: block;
  width: 100%;
  padding: 5px 0 0 15px;
  font-size: medium;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}
.module-container{
  margin-top: 5%;
}
.module-header{
  background-color: #11101d;
  color: white;
  font-size: 1.3em;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 5px;
}
.drop-down {
  width: 100%;
  height: 40px;
  padding: 5px;
  font-size: 15px;
  border:1px solid #ced4da;
  border-radius: 4px;
} 
.cross-btn{
  /* border: 2px solid white; */
  font-weight: 700;
  padding: 2px 12px;
  cursor: pointer;
}

.module-label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 5px 5px;
}

.btn-container{
display: flex;
justify-content: end;
margin: 1% 0;
}
.add-module {
border: none;
background-color: #11101d;
color: white;
padding:5px 15px;
border-radius: 5px;
}
