.inp-box {
  padding: 0 0%;
  width: 45%;
}
.drop-down-select {
  width: 100%;
  font-size: 15px;
  border-color: #ced4da;
  border-radius: 5px;
}
.drop-down {
  width: 100%;
  height: 40px;
  padding: 5px;
  font-size: 15px;
  border:1px solid #ced4da;
  border-radius: 4px;
} 
.select-box{
  width: 45%;
}
.select-option {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: rgb(32, 32, 32);
  padding: 10px;
  font-size: 15px;
}
.label {
  white-space: nowrap;
  text-align: end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 5px 5px;
  text-transform: capitalize;
}

.text-box {
  width: 100%;
}
.text-area-label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 5px 5px;
}
.text-area {
  width: 100%;
  padding: 5px 0 0 15px;
  font-size: medium;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}
