.dashboard {
  margin: 1%;
}
.header {
  background-color: white;
  display: flex;
  margin-bottom: 1%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}
.heading {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: x-large;
  margin-bottom: 0;
}
.service-btn {
  background-color: transparent;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #888888;
  padding: 5px 10px;
}
.service-btn-selected {
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  color: #111011;
  font-weight: 500;
  border-bottom: 2px solid #111011;
  padding: 5px 10px;
}
.project-btn {
  background-color: transparent;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #888888;

  padding: 5px 10px;
}
.project-btn-selected {
  background-color: transparent;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  color: #111011;
  font-weight: 500;

  border-bottom: 2px solid #111011;
  padding: 5px 10px;
}
