/* TextBox */

.text-box {
  width: 100%;
}
.text-area-label {
  white-space: nowrap;
  text-align: right;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}
.text-area {
  width: 100%;
  padding: 5px 0 0 15px;
  font-size: medium;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
}

/* DropDown */
.inp-box {
  padding: 0 0%;
  width: 45%;
}
.label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}
.drop-down {
  width: 100%;
  height: 6.5vh;
  font-size: 15px;
}

/* GST & PAN INPUT */

.inp-label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}
.label-disable {
  color: grey;
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}
.inp {
  font-size: 15px;
  padding: 8px 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);
}

ion-icon {
  font-size: 24px;
  padding-top: 5px;
}

.select-option {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  color: #11101d;
  font-size: 1.2em;
  font-weight: 600;
  margin-left: 10px;
  padding: 0.6% 1%;
}
.customer-type {
  margin-top: 0.3%;
  padding: 1% 5%;
  background-color: white;
}
.customer-type label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 10px 0 0;
}
.select-box {
  display: flex;
  align-items: center;
}
.container {
  height: auto;
  padding: 1% 0;
  width: 100%;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.inp-container {
  display: flex;
  justify-content: center;
}

.check-container {
  display: flex;
  justify-content: end;
  align-items: center;
}
.check-inp {
  margin: 1% 30% 0 1%;
}
.check-bx-label {
  text-align: end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin-top: 0.5%;
}
.add-contact-btn {
  background-color: #11101d;
  color: white;
  border-radius: 50%;
  border: 2px solid #11101d;
}
.add-contact-btn:hover {
  background-color: #414046;
  border: 2px solid transparent;
  transition: 0.3s;
}
