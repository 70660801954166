.inp-box {
    padding: 0 0%;
    width: 45%;
}

.inp-box label {
    white-space:nowrap;
    text-align: end;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: 500;
    margin:0 0 2px 5px;
}
.invalid{
    border-color:red;
    background-color: rgba(255, 0, 0, 0.205);
    width: 100%;
    height:39px;
    padding: 10px;
    font-size: 15px;
    margin-bottom: 0;
}