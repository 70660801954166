/* .table {
    background-color: #11101d;
    color: white;
    border-radius: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
      text-transform: capitalize;
    font-size: 1em;
  }
  .icon {
    margin: 0 2%;
    filter: invert(53%) sepia(1%) saturate(931%) hue-rotate(190deg)
      brightness(100%) contrast(100%);
  }
  th{
    width: 10vw;
  }
  .serialNo{
    width: 5vw;
  }
  .table-container{
    margin:0 2% 3% 2%;
    width: 75%;
  }
  .company-header {
    font-size: 1.1em;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 600;
    background-color: #212529;
    color: white;
   display: grid;
   grid-template-columns: 1fr 1fr 2fr 2.5fr;
   margin-bottom: 2%;
  }
  .company-header div{
    padding: 2%;
  height: 100%;
  }
  .company-header div:nth-of-type(1){
    padding: 6% 0 0% 6%;
    border-right: 1px solid grey;
  }
  .company-header div:nth-of-type(2){
    padding: 6% 0 0 6%;
    border-right: 1px solid grey;
  }
  .company-header div:nth-of-type(3){
    padding: 3% 0 0 3%;
    border-right: 1px solid grey;
  }
  .btn-container{
    display: flex;
    justify-content: flex-end;
  }
  
  .add-service{
    display: flex;
    color: white;
    background-color: #212529;
    padding: 5px;
    border-radius: 5px;
  }
  .select-box{
    display: flex;
    align-items: center;
    width: 45%;
  
}
.label {
    width: 9vw;
    text-align: end;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: 500;
    margin:0 10px 0 0;
  }
  .select{
    
      border:1px solid #ced4da;
      color: black;
      border-radius: 5px;
      width: 70%;
      margin-left: 2%;
      height: 6vh;
      padding: 10px;
      font-size: 15px;
  }
  .not-purchase{
  
    text-align:center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: 500;
    margin:0 10px 0 0;
  } */

  .inp-box {
    padding: 0 0%;
    width: 45%;
  }
  .label {
    white-space: nowrap;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 17px;
    font-weight: 500;
    margin: 0 0 2px 5px;
  }
  .drop-down {
    width: 100%;
    height: 6vh;
    padding: 5px;
    font-size: 1.1em;
    border:1px solid #ced4da;
    border-radius: 4px;
  } 
.table {
  max-width: 100%;
  background-color: white;
  border-radius: 5px;
  padding: 1%;
  box-shadow: 1px 1px 5px grey;
  transition: 0.5s;
}
.header {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 1%;
  border-bottom: 1.5px solid #eee;
}
.header div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header img {
  width: 25px;
  margin-right: 5px;
}

.header span {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.1em;
}

.tr {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1.5px solid #eee;
}
.tr div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}
.tr span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: grey;
  font-size: 1.1em;
}
.empty {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.2em;
  text-align: center;
  font-weight: 500;
  margin: 2% 0;
  padding: 20px 0;
  background-color: white;
  border-radius: 5px;
}
.action-container{
  display: flex;
  justify-content: space-between;
}