.select {
  width: 20vw;
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: black;
  margin: 0 3% 0 1%;
  height: 6.5vh;
  padding: 10px;
  font-size: 15px;
}
.select-box {
  display: flex;
  align-items: center;
}
.select-extension {
  width: 30vw;
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: black;
  margin: 0 3% 0 1%;
  height: 6.5vh;
  padding: 10px;
  font-size: 15px;
}
.inp-box {
  padding: 0 0%;
  width: 45%;
}
.label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}
.drop-down {
  width: 100%;
  height: 5vh;
  padding: 5px;
  font-size: 1.1em;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
