.box {
  width: 98%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  margin: 1% 0 1% 1%;
  flex-direction: column;
  align-items: center;
  color: #11101d;
  transition: 0.5s;
}


.box-header {
  width: 100%;
  margin: 1% 0 1% 0%;
  color: white;

  background-color: #11101d;
  border-radius: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px grey;
}


.box-header div:nth-of-type(1) {
  padding: 1% 0 1% 1%;
  display: flex;
  align-items: center;
}

.box-header div:nth-of-type(2) {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 1%;
}

.box-header div:nth-of-type(2) button {
  background-color: transparent;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #11101d;
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
}

.box-header h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  margin: 0 0 0 1%;
  font-size: 2em;
}
a{
  text-decoration: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
color: #11101d;
border: none;
font-size: 16px;
font-weight: 600;
}
.box-body {
  width: 100%;
  margin: 1% 0 1% 0%;
  background-color: #eee;
  border-top: none;
  height: auto;

}
.body-header {
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 1.2em;
  padding: 0 1%;
  border-radius: 5px;
  color: white;
  border: 2px solid #11101d;
  background-color: #11101d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 5px grey;
}
.body-header span{
display: flex;
align-items: center;
}
.breadcrumb{
  text-transform: capitalize;
}
/* @media(min-width:) */
