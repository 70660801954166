.table{
  width: 98%;
  background-color: #eee;
  margin: 1% 0 1% 1%;
  text-align: center;
  transition: 0.5s;
  border-collapse: separate;
  border-spacing: 0 0.4em;
}


.thead {
  background-color: #11101d;
  color: white;
  height: 7vh;
  border-radius: 10px;
}

.thead th {
  font-size: 1.1em;
  font-weight: 600;
  font-family: "Segoe UI";
}

.thead th:nth-of-type(1) {
  width: 5vw;
}
.tbody {
  background-color: #eee;
}
.trow {
  background-color: white;
  height: 6.5vh;
}

.tbody td {
  font-size: 1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
}
.inp-box {
  padding: 0 0%;
  width: 45%;
}
.label {
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 5px;
}
.drop-down {
  width: 100%;
  height: 6vh;
  padding: 5px;
  font-size: 1.1em;
  border-color: #ced4da;
  border-radius: 4px;
}