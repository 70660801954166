.small-box {
    width: 100%;
    height: auto;
    margin: 0.2% 0;
    /* border-radius: 5px; */
     /* box-shadow: 1px 1px 2px grey; */
    background-color: white;
    padding: 1.2% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


